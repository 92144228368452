import jQuery from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";

const datepicker = require('js-datepicker')

const picker = datepicker('.datepicker', {
  formatter: (input, date, instance) => {
    const value = date.toLocaleDateString()
    input.value = value // => '1/1/2099'
  },
  minDate: new Date(),
  startDay: 1,
  customDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  customMonths: ['Янв', 'Феб', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  // noWeekends: true, // Выключить выходные
  // dateSelected: new Date(),
  disableYearOverlay: true,
})



jQuery(function($) {
  // jQuery("body").css("color", "blue");
  
  $('#dismiss, .overlay-sidebar').on('mousedown touchstart', function () {
    $('.hamburger').addClass('is-active');
  });
  
  $('#dismiss, .overlay-sidebar').on('click', function () {
    $('.hamburger').removeClass('is-active');
    // hide sidebar
    $('#sidebar').removeClass('active');
    // hide overlay
    $('.overlay-sidebar').removeClass('active');
  });

  $('#sidebarCollapse').on('click', function () {
    $('.hamburger').addClass('is-active');
    // open sidebar
    $('#sidebar').addClass('active');
    // fade in the overlay
    $('.overlay-sidebar').addClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });

  
  $("form #name, form #email, form #type-of-print").attr("required", "true");

  // Plusminus buttons
  $("form .plusminus-container").append('<div class="inc button button-plus noselect">+</div><div class="dec button button-minus noselect">-</div>');
  $(".button").on("click", function() {
    var $button = $(this);
    var oldValue = $button.parent().find("input").val();
    if (oldValue == '') { oldValue = '0'; }

    if ($(this).hasClass('button-plus')) {
      var newVal = parseFloat(oldValue) + 1;
    } else {
      if (oldValue > 1) {
        var newVal = parseFloat(oldValue) - 1;
      } else {
        newVal = 1;
      }
    }
    $button.parent().find("input").val(newVal);
  });





      // Form sender
      function getEmailDetail() {
        $('.form-booking').on('submit', function(e) {
          e.preventDefault();

          var $this = $(this.querySelector('#form-book'));
          var o = {};
          var a = $this.serializeArray();

          // o['form-title'] = $this.find('.form-title').html();
          o['title'] = document.title;
          o['url'] = window.location.href;
          $.each(a, function() {
            if (o[this.name]) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });
          // console.log(a);
          console.log(o);

          // $('.send-button').attr("disabled","disabled");
      
          var data = o;
          console.log(data);
          
          var url = 'phpmailer.php';
          if(url) {
            $.post(url, data, function(response) {
              console.log(response);
              $('.send-button').attr("disabled",false);
              if (response == 'wrong') {
                $('.send-button-container').append('<div>Произошла ошибка. Повторите попытку позже.</div>');
              }
              if (response == 'ok') {
                alert("Сообщение успешно отправлено. В ближайшее время наши менеджеры свяжутся с вами!");
                setTimeout(function() {
                  $this.trigger("reset");
                }, 400);
              }
            });
          }
        });
    }

    getEmailDetail();
    
// jQuery END        
});


if ( null == document.getElementById('map') ) {
  // console.log('div#map false');
} else {
    // console.log('div#map true');
    ymaps.ready(init);

    function init () {
      var myMap = new ymaps.Map("map", {
          controls: ['fullscreenControl'],
          center: [53.929192, 27.599650],
          zoom: 14
      }, {
          searchControlProvider: 'yandex#search'
      });

      myMap.controls.add('zoomControl', {
        size: "small",
        position: {
          top: 10,
          left: 10
        }
      });

      myMap.controls.add('typeSelector', {
        maxWidth: 65
      });

      myMap.geoObjects
      .add(new ymaps.Placemark(myMap.getCenter(), {
          balloonContent: 'Минск, пер. Калининградский, 19А - 3'
      }, {
          preset: 'islands#icon',
          iconColor: '#1F7377'
      }));
    }
}